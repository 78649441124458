import { appToasted } from '../../../utils/vue-toasted/app_toasted.js'
import { appCookies } from '../../../utils/vue3-cookies/app_cookies.js'
import inflection from 'inflection'
import { isEmpty, isEqual, capitalize, pickBy, mapValues } from 'lodash'
import { globalModule } from '../../../store/globalModule'
import helpers from '../../../utils/helpers'
import API from '../../../vue_shared/api' // eslint-disable-line import/no-useless-path-segments
import NrSentry from '../../../utils/nr_sentry'
import columns from '../components/columns'
import { router } from '../../../routes/router'
import * as VueRouter from 'vue-router'
import { getClosureResponse, getTag } from './api'
import { parseFilterParams } from './parse-filter-params.ts'
import {
  fetchUsersByType,
  fetchOptionByType,
  fetchUserByType
} from './requests/filters.ts'
import { QUERY_FILTER_IDS_TO_OPTION_KEY_MAP } from '../../../store/filters-state.ts'

export default {
  deleteAllRequests: ({ commit }) => {
    API.deleteRequests()
      .then(() => {
        commit('setRequests', [])
        commit('setAllRequestsCount', 0)
        commit('setSearchResultsCount', 0)
        commit('setIsLoading', false)
      })
      .catch((error) => {
        if (error.response.status === 403) {
          appToasted().error('You do not have permission to delete requests.')
        } else {
          appToasted().error('An error occured. Please try again.')
        }
      })
  },

  fetchSavedView: async ({ commit, rootState }) => {
    try {
      if (!rootState.currentUser) return
      if (rootState.currentUser.user_role === 'guest') return

      const { data } = await API.savedViews()
      delete data.my_requests
      commit('setSavedViews', data)
    } catch (err) {
      const nrError = new Error('Saved views failed to load', { cause: err })
      NrSentry.captureError(nrError)
    }
  },

  setResetSortIcons: ({ commit }, val) => {
    commit('setResetSortIcons', val)
  },

  applyFilters: ({ state, commit }, queryParams = {}) => {
    let filterParams = parseFilterParams(state.filters)
    const queryFilters = parseFilterParams(queryParams)

    if (!isEmpty(queryParams) && !isEqual(queryFilters, filterParams)) {
      filterParams = queryFilters
      commit('setFilters', filterParams)
    }

    router
      .push({ name: 'all-requests', query: filterParams })
      .catch((error) => {
        // Dup. navigation shouldn't be throwing errors.
        if (
          VueRouter.isNavigationFailure(
            error,
            VueRouter.NavigationFailureType.duplicated
          )
        ) {
          return
        }

        throw error
      })
  },

  configureRequestsTableColumns: ({ commit }, ability) => {
    const columnsFromCookie = helpers.returnColumnsFromCookie(
      appCookies(),
      globalModule.state.currentUser
    )
    const requestColumns = columnsFromCookie || columns
    const customColumns = helpers.selectAuthorizedRequestColumns(
      requestColumns,
      globalModule,
      ability
    )
    commit('setColumns', customColumns)
  },

  fetchRequests: async ({ commit, dispatch }) => {
    commit('setIsLoading', true)
    try {
      const params = parseFilterParams(router.currentRoute.value.query)
      const { data } = await API.fetchRequestsList(params)
      commit('setSearchResultsCount', data.total_count)
      commit('setAllRequestsCount', data.total_count)

      commit('setRequests', data.requests)
      commit('setIsLoading', false)
      if (!data.requests.length) {
        commit('setColumns')
      }
      dispatch('setTableHeaderTitle')
      dispatch('setTableHeaderTitleTooltip')
    } catch (err) {
      // if we canceled the request, we don't need to show an error message
      if (err.message !== 'canceled') {
        commit('setIsLoading', false)
        commit('setResetSortIcons', false)
        throw err
      }
    }
  },

  fetchFilterOptions: async (
    { commit, state },
    { search_text = '', filterName } // eslint-disable-line camelcase
  ) => {
    const { filterFetchState } = state

    if (
      filterFetchState[filterName].search_text === search_text && // eslint-disable-line camelcase
      state.filterFetchState[filterName].end
    ) {
      return
    }

    // eslint-disable-next-line camelcase
    if (filterFetchState[filterName].search_text === search_text) {
      commit('setFilterFetchState', {
        [filterName]: {
          page: state.filterFetchState[filterName].page + 1,
          fetching: true
        }
      })
    }

    // eslint-disable-next-line camelcase
    if (filterFetchState[filterName].search_text !== search_text) {
      const key = Object.keys(QUERY_FILTER_IDS_TO_OPTION_KEY_MAP).find(
        (key) => QUERY_FILTER_IDS_TO_OPTION_KEY_MAP[key] === filterName
      )
      const filterIds = state.filters[key]
      const filterOptions = state.filterOptions[filterName]
      const selectedOptionsFromFilterState = filterOptions.filter((option) =>
        filterIds.includes(option.id)
      )
      await commit('setFilterOptions', { [filterName]: [] })
      await commit('setFilterOptions', {
        [filterName]: selectedOptionsFromFilterState
      })
      commit('setFilterFetchState', {
        [filterName]: { page: 1, end: false, fetching: true }
      })
    }
    commit('setFilterFetchState', { [filterName]: { search_text } })
    fetchUsersByType({
      type: filterName,
      search_text,
      page_number: state.filterFetchState[filterName].page
    })
      .then((res) => {
        if (res.length) {
          commit('setFilterOptions', { [filterName]: res })
        }
        commit('setFilterFetchState', {
          [filterName]: { end: res.length < 25 }
        })
      })
      .finally(() => {
        commit('setFilterFetchState', { [filterName]: { fetching: false } })
      })
  },

  generateReport: ({ dispatch, commit }, payload) => {
    API.generateReport(payload)
      .then((response) => {
        const jobId = response.data.job_id
        if (!jobId) {
          throw new Error('No job id present')
        }
        const reportType = payload.report_type
        const jobInfo = response.data
        jobInfo.report_type = reportType
        // set job info in localstorage for later retrieval
        helpers.setReportJobInLocalStorage(jobInfo)
        commit('setReportPendingStatus', true)
        appToasted().success('Report will download automatically when ready.')
        // poll job until it completes
        dispatch('pollReportJob', [jobId])
      })
      .catch((e) => {
        const nrError = new Error('Report failed to start', { cause: e })

        NrSentry.captureError(nrError)

        appToasted().error('An error has occured while generating a report')
      })
  },

  pollReportJob: ({ dispatch, commit }, jobIds) => {
    const pollJob = setInterval(() => {
      API.jobStatuses({ jid: jobIds })
        .then((response) => {
          const jobs = response.data.jobs
          // Stop polling when no pending jobs left in localstorage
          helpers.stopReportJobPolling(pollJob)
          if (!jobs.length) {
            const nrError = new Error('Report jobs no longer available')
            commit('setReportPendingStatus', false)

            NrSentry.captureError(nrError, {
              tags: {
                jobId: jobIds
              }
            })

            return
          }
          jobs.forEach((job) => {
            // remove failed job from localstorage
            if (helpers.backgroundJobFailed(job.status)) {
              helpers.removeReportJobFromLocalStorage(job.id)
              commit('setReportPendingStatus', false)
              appToasted().error(
                'An error has occured while generating a report.'
              )
              {
                const nrError = new Error('Report job failed')

                NrSentry.captureError(nrError, {
                  tags: {
                    jobId: job.id
                  }
                })
              }
            } else if (helpers.backgroundJobComplete(job.status)) {
              // grab job type and filepath from localstorage
              const reportInfo = helpers.fetchReportInfoFromLocalStorage(job.id)
              // remove completed job from localstorage
              helpers.removeReportJobFromLocalStorage(job.id)
              commit('setReportPendingStatus', false)
              // Check if file is ready and setup report file for download
              dispatch('setupReportFile', reportInfo)
            }
          })
        })
        .catch((e) => {
          commit('setReportPendingStatus', false)
          const nrError = new Error('Report job polling failed', { cause: e })
          NrSentry.captureError(nrError)
          appToasted().error('An error has occurred while generating a report')
        })
    }, 5000)
  },

  setupReportFile: ({ dispatch }, reportInfo) => {
    API.fetchReportFile({
      filepath: reportInfo.filePath,
      file_type: reportInfo.reportType
    })
      .then((response) => {
        // download file
        const signedS3Url = response.data.url
        const fileName = response.data.filename
        API.downloadReportFile(signedS3Url)
          .then((response) => {
            const reportData = response.data
            helpers.forceFileDownload({ reportData, fileName })
            dispatch('fetchSavedReports')
          })
          .catch((e) => {
            // catch error if it fails to download in the browser
            appToasted().error(
              'An error has occurred while downloading your report.'
            )
            const nrError = new Error(
              'An error has occurred while downloading your report.',
              { cause: e }
            )
            NrSentry.captureError(nrError)
          })
      })
      .catch(() => {
        // catch error if something goes wrong with the presigned url
        appToasted().error(
          'An error has occured while downloading your report.'
        )
      })
  },

  // eslint-disable-next-line no-empty-pattern
  setTableColumnsInCookie: (_, columns) => {
    const data = { columns: columns, user: globalModule.state.currentUser }
    if (!globalModule.state.currentUser.id) {
      appCookies().remove('publicTableColumns')
      appCookies().set('publicTableColumns', {
        columns: columns,
        user: {}
      })
    } else {
      appCookies().remove(
        `user${globalModule.state.currentUser.id}TableColumns`
      )
      appCookies().set(
        `user${globalModule.state.currentUser.id}TableColumns`,
        data
      )
    }
  },

  async setAddSavedViews({ commit, dispatch, state }, filterName) {
    const { filters } = state
    const payload = {
      filter_name: filterName,
      filters: parseFilterParams(filters)
    }
    const { data } = await API.addSavedViews(payload)
    appCookies().set('savedView', data.id)
    commit('setSavedViews', [data, ...state.savedViews])
    commit('setSelectedSavedView', data)
    commit('resetFilters')
    if (data.filters) {
      commit('setFilters', data.filters)
      dispatch('applyFilters')
    }
  },

  setDeleteSelectedView({ dispatch, commit, state }, val) {
    API.deleteSavedViews(val)
      .then(() => {
        const newSavedViews = state.savedViews.filter((item) => item.id !== val)
        commit('resetFilters')
        commit('setSavedViews', newSavedViews)
        commit('setSelectedSavedView')
        dispatch('applyFilters')
        dispatch('fetchRequests')
        appCookies().remove('savedView')
        appToasted().success('Saved view deleted successfully')
      })
      .catch((err) => {
        throw err
      })
  },

  setTableHeaderTitle: ({ commit, state, getters }) => {
    const filters = getters.activeFilters
    const count = state.searchResultsCount

    if (filters.length > 0) {
      const firstItem = capitalize(filters[0].split('_').join(' '))
      commit(
        'setTableHeaderTitle',
        `${inflection.inflect('Request', count)} filtered by ${firstItem} ${
          filters.length <= 1 ? '' : 'and ' + (filters.length - 1) + ' others'
        }`
      )
    } else {
      commit('resetHeaderTitle')
      commit(
        'setTableHeaderTitle',
        `${inflection.inflect('Request', count)} found`
      )
    }
  },

  setTableHeaderTitleTooltip: ({ commit, state }) => {
    const filterParams = parseFilterParams(state.filters)
    const readableFilters = {
      'Search term': filterParams.search_term,
      'Point of contact assigned': helpers.setMyRequestsTooltipText(
        filterParams.request_poc_ids,
        globalModule.state.currentUser,
        state.filterOptions.pocs
      ),
      'Staff assigned': helpers.setMyRequestsTooltipText(
        filterParams.request_helper_ids,
        globalModule.state.currentUser,
        state.filterOptions.staff
      ),
      'Requesters assigned': helpers.setMyRequestsTooltipText(
        filterParams.requester_ids,
        globalModule.state.currentUser,
        state.filterOptions.requesters
      ),
      'Request status': (() => {
        const statuses = []
        if (filterParams.closed) statuses.push('Closed')
        if (filterParams.open) statuses.push('Open')
        if (filterParams.pending) statuses.push('Pending')
        if (filterParams.due_soon) statuses.push('Due Soon')
        if (filterParams.overdue) statuses.push('Overdue')
        return statuses.join(', ')
      })(),
      'Request visibility': (() => {
        const visibilities = []
        if (filterParams.published) visibilities.push('Published')
        if (filterParams.embargoed) visibilities.push('Embargoed')
        if (filterParams.staff_visible) visibilities.push('All staff')
        if (filterParams.department_visible) visibilities.push('Department')
        if (filterParams.department_published)
          visibilities.push('Published - department only')
        if (filterParams.restricted) visibilities.push('Restricted')
        return visibilities.join(', ')
      })(),
      'Request date (end)': filterParams.request_date_end_date,
      'Request date (start)': filterParams.request_date_start_date,
      'Due date (end)': filterParams.due_date_end_date,
      'Due date (start)': filterParams.due_date_start_date,
      'Departments assigned': filterParams.department_ids
        ?.map(
          (id) =>
            state.filterOptions.departments.find((obj) => obj.id === id)?.name
        )
        ?.join(', '),
      'Tags assigned': filterParams.tag_ids
        ?.map(
          (id) => state.filterOptions.tags.find((obj) => obj.id === id)?.name
        )
        ?.join(', '),
      'Closure responses': filterParams.close_request_type_ids
        ?.map(
          (id) =>
            state.filterOptions.closure_responses.find((obj) => obj.id === id)
              ?.name
        )
        ?.join(', ')
    }

    const tooltipText = Object.entries(readableFilters)
      .filter(([_key, value]) => value)
      .map(([key, value]) => ` ${key}: ${value}; `)
      .join('')

    if (!tooltipText) {
      commit('setTableHeaderTitleTooltip', '')
    } else {
      commit('setTableHeaderTitleTooltip', `Filtered by: ${tooltipText}`)
    }
  },

  setColumns: ({ commit }, val) => commit('setColumns', val),

  resetHeaderTitle: ({ commit }) => commit('resetHeaderTitle'),

  setSavedViews: ({ commit }, val) => commit('setSavedViews', val),

  setIsFetching: ({ commit }, val) => commit('setIsFetching', val),

  setFilters: ({ commit }, val) => {
    commit('setFilters', val)
  },

  setCurrentPageNumber: ({ commit }, val) => {
    commit('setCurrentPageNumber', +val || 1)
  },

  setIsLoading: ({ commit }, val) => commit('setIsLoading', val),

  setReqSubmitDisabled: ({ commit }, val) =>
    commit('setReqSubmitDisabled', val),

  setSearchResultsCount: ({ commit }, val) =>
    commit('setSearchResultsCount', val),

  setAllRequestsCount: ({ commit }, val) => commit('setAllRequestsCount', val),

  addUploadedReqToState: ({ commit }, val) =>
    commit('addUploadedReqToState', val),

  toggleFilter: ({ commit }) => commit('toggleFilter'),

  setFilterData: ({ commit }, val) => commit('setFilterData', val),

  setHeroOpen: ({ commit }, val) => {
    commit('setHeroOpen', val)
  },

  async fetchClosureResponse(_, id) {
    return getClosureResponse(id)
  },

  async fetchTag(_, id) {
    return getTag(id)
  },

  fetchOptionsFromFilterState({ state, commit, rootState }) {
    const fetchFiltersWithIds = pickBy(state.filters, (value) => {
      return Array.isArray(value)
    })

    mapValues(fetchFiltersWithIds, (ids, key) => {
      switch (QUERY_FILTER_IDS_TO_OPTION_KEY_MAP[key]) {
        case 'pocs':
        case 'staff':
          ids.forEach((id) => {
            fetchUserByType(id, QUERY_FILTER_IDS_TO_OPTION_KEY_MAP[key]).then(
              (res) => {
                commit('setFilterOptions', {
                  [QUERY_FILTER_IDS_TO_OPTION_KEY_MAP[key]]: [res]
                })
              }
            )
          })
          break
        case 'requesters':
          if (
            rootState.currentUser &&
            rootState.currentUser.user_role === 'guest'
          ) {
            ids.forEach((id) => {
              fetchUserByType(id, QUERY_FILTER_IDS_TO_OPTION_KEY_MAP[key]).then(
                (res) => {
                  commit('setFilterOptions', {
                    [QUERY_FILTER_IDS_TO_OPTION_KEY_MAP[key]]: [res]
                  })
                }
              )
            })
          }
          break
        case 'closure_responses':
        case 'departments':
        case 'tags':
          ids.forEach((id) => {
            fetchOptionByType(id, QUERY_FILTER_IDS_TO_OPTION_KEY_MAP[key]).then(
              (res) => {
                commit('setFilterOptions', {
                  [QUERY_FILTER_IDS_TO_OPTION_KEY_MAP[key]]: [res]
                })
              }
            )
          })
          break
      }
    })
  },

  fetchSearchIntegrations: async ({ commit, rootState }) => {
    const accountId = rootState?.account?.id
    if (!accountId) {
      commit('setSearchIntegrations', null)
      return false
    }

    try {
      const { data } = await API.fetchSearchIntegration({
        account_id: accountId
      })

      commit('setSearchIntegrations', {
        ...data.search_integrations,
        products: data.products
      })
    } catch (err) {
      // if we canceled the request, we don't need to show an error message
      if (err.message !== 'canceled') {
        commit('setSearchIntegrations', null)
        throw err
      }
    }
  }
}
