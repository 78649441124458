import {
  State,
  DocumentMetrics,
  DocumentPii,
  Documents,
  UpdatedReviewState
} from './state'

export interface Mutations {
  setDocumentMetrics(state: State, payload: DocumentMetrics): void
  setDocumentPii(state: State, payload: DocumentPii): void
  setDocumentList(state: State, payload: Documents): void
  setDocument(state: State, payload: UpdatedReviewState): void
}

export const mutations: Mutations = {
  setDocumentMetrics(state, payload) {
    state.documentMetrics = payload
  },
  setDocumentPii(state, payload) {
    state.documentPii = payload
  },
  setDocumentList(state, payload) {
    state.documents = payload
  },
  setDocument(state, payload) {
    const docIndex = state.documents.findIndex((doc) => payload.id === doc.id)
    if (docIndex === -1) {
      return
    }
    const document = state.documents[docIndex]
    state.documents.splice(docIndex, 1, {
      ...document,
      review_status: payload.review_status
    })
  }
}
