import * as http from '../../../vue_shared/http'
import {
  DocumentMetrics,
  DocumentPii,
  Documents,
  UpdatedReviewState
} from './state'

export async function getMetrics(): Promise<DocumentMetrics> {
  return http.get<DocumentMetrics>('/admin/document_dashboard/metrics')
}

export async function getDocumentPii(
  queryParams?: string
): Promise<DocumentPii> {
  return http.get<DocumentPii>(`/client/document_scans/filters${queryParams}`)
}

export async function updateDocReviewStatus(
  params: UpdatedReviewState
): Promise<UpdatedReviewState> {
  return http.put<UpdatedReviewState, UpdatedReviewState>(
    `/client/document_scans/${params.id}`,
    params
  )
}

export async function getDocumentList(queryParams: string): Promise<Documents> {
  return http.get<Documents>(`/client/document_scans${queryParams}`)
}

interface DocumentScan {
  id: number
  document_id: number // eslint-disable-line camelcase
  review_status: string // eslint-disable-line camelcase
  severity: string
  title: string
  document_path: string // eslint-disable-line camelcase
  findings: {}
  file_size: number // eslint-disable-line camelcase
  upload_date: Date // eslint-disable-line camelcase
  visibility: string
  file_type: string // eslint-disable-line camelcase
  pretty_id?: string // eslint-disable-line camelcase
  request_path?: string // eslint-disable-line camelcase
}
export async function getDocumentScan(id: number): Promise<DocumentScan> {
  return http.get<DocumentScan>(`/client/document_scans/${id}`)
}
