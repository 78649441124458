import { difference } from 'lodash'

export default {
  activeDoc: (state) => state.activeDoc,
  activeDocId: (state) => state.activeDocId,
  activeFolder: (state) => state.activeFolder,
  activeModal: (state) => state.activeModal,
  activeReviewState: (state) => state.activeReviewState,
  activeCheckAll: (state) => state.activeCheckAll,
  activeOrSelectedDocIds: (state) =>
    state.activeDocId !== '' && state.checkedDocIds.length === 0
      ? [state.activeDocId]
      : difference(state.checkedDocIds, state.processingDocIds),
  autoSaveState: (state) => state.autoSaveState,
  checkedDocIds: (state) => state.checkedDocIds,
  checkedDocIdsCount: (state) => state.checkedDocIds.length,
  currentUser: (state) => state.currentUser,
  docViewerPath: (state) => state.docViewerPath,
  draftRedactedDocIds: (state) =>
    Object.values(state.documents)
      .filter((d) => d.redaction_layers.length > 0)
      .map((d) => d.id),
  draftRedactionData: (state) => state.draftRedactionData,
  folders: (state) => state.folders,
  folderOptions: (state) => state.folderOptions,
  getDocuments: (state) => state.documents,
  getDocument: (state) => (id) => state.documents[id],
  infiniteId: (state) => state.infiniteId,
  infinitePage: (state) => state.infinitePage,
  jobStarted: (state) => state.jobStarted,
  messageTemplateOptions: (state) => state.messageTemplateOptions,
  pageData: (state) => state.pageData,
  processingDocIds: (state) => state.processingDocIds,
  prettyId: (state) => state.prettyId,
  marks: (state) => state.marks,
  markupUpdatedAt: (state) => state.currentMarkupUpdatedAt,
  redactionComment: (state) => state.redactionComment,
  redactionLayerCount: (state) => state.redactionLayerCount,
  redactionLayerId: (state) => state.redactionLayerId,
  reviewStates: (state) => state.reviewStates,
  reviewStateCounts: (state) => state.reviewStateCounts,
  searchTerm: (state) => state.searchTerm,
  searchProcesses: (state) => state.searchProcesses,
  viewingSessionId: (state) => state.viewingSessionId,
  totalDocCount: (state) => state.totalDocCount
}
