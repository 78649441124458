export default {
  request: {},
  documents: [],
  documentsFolders: [],
  totalDocumentsFoldersCount: 0,
  documentsProcessingIds: [],
  requestStaff: [],
  requestTags: [],
  requestTasks: [],
  currentRequestTasksCount: 0,
  totalRequestTasksCount: 0,
  totalRequestTimelineCount: 0,
  displayExternalMsgBox: false,
  displayInternalMsgBox: false,
  displayMsgBox: false,
  displayCreateInvoiceMsgBox: false,
  displayCreateTaskMsgBox: { display: false, type: 'new' },
  displayStaffTimeBox: false,
  displayEditRequestTextBox: false,
  currentAction: null,
  closureTemplates: [],
  editingTask: {},
  previewRequestPage: true,
  requestTimeline: [],
  requestStaffHours: {},
  requestStaffCostUnpaid: '',
  invoiceTemplates: [],
  messageTemplates: [],
  requestInvoices: [],
  searchDocumentResultsCount: 0,
  currentDocumentsCount: 0,
  totalDocumentsCount: 0,
  documentsStateTimestamp: 0,
  currentDocumentFilters: {},
  selectedChangeDocVisibility: '',
  messageToRequester: '',
  activeModal: '',
  folderLimit: 25,
  requestDocumentRisk: {},
  dataOptions: {
    requesters: [],
    tags: [],
    message_templates: [],
    staff: [],
    departments: []
  },
  dataFetchState: {
    requesters: { page: 0, search_text: '', end: false, fetching: false },
    tags: { page: 0, search_text: '', end: false, fetching: false },
    staff: {
      page: 0,
      search_text: '',
      end: false,
      fetching: false
    },
    departments: {
      page: 0,
      search_text: '',
      end: false,
      fetching: false
    },
    message_templates: {
      page: 0,
      search_text: '',
      end: false,
      fetching: false
    }
  },
  invoiceLineItemForm: {
    selectedLineItem: '',
    lineItemQuantity: 1
  },
  invoiceLineItemType: '',
  invoiceSelectedSurchargeItem: '',
  totalSelectedLineItems: [],
  totalSelectedSurchargeItems: [],
  subtotalInvoiceAmount: 0,
  totalInvoiceAmount: 0,
  selectedStaffHour: {},
  activeAddItemModal: false,
  filteredRequestLoading: true,
  invoiceItemToEdit: null,
  selectedTimelineEvent: null,
  pinnedNote: {},
  jobPollIntervalId: null
}
