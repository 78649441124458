/* eslint-disable no-param-reassign */
export default {
  addUploadedDocToState: (state, val) => {
    state.uploadedDocs.push(val)
  },

  setColumns: (state, val) => {
    state.columns = val
  },

  setIsLoading: (state, val) => {
    state.isLoading = val
  },

  resetUploadedDocs: (state) => {
    state.uploadedDocs = []
  },

  setCurrentPageNumber: (state, val) => {
    state.currentPageNumber = val
  },

  setAllDocumentsCount: (state, val) => {
    state.allDocumentsCount = val
  },

  setDocuments: (state, val) => {
    state.documents = val
  },

  setSearchResultsCount: (state, val) => {
    state.searchResultsCount = val
  },

  setSearchTerm: (state, val) => {
    state.searchTerm = val
  },

  setFolderFilter: (state, val) => {
    state.folderFilter = val
  },

  setFolderFilterState: (state, val) => {
    state.folderFilterState = { ...state.folderFilterState, ...val }
  },

  setUploadDateRange: (state, { position, date }) => {
    if (position === 'start') {
      state.uploadDateRange = [date, state.uploadDateRange[1]]
    } else if (position === 'end') {
      state.uploadDateRange = [state.uploadDateRange[0], date]
    }
  },

  setDocumentDateRange: (state, { position, date }) => {
    if (position === 'start') {
      state.documentDateRange = [date, state.documentDateRange[1]]
    } else if (position === 'end') {
      state.documentDateRange = [state.documentDateRange[0], date]
    }
  },

  setDocSubmitDisabled: (state, val) => {
    state.docSubmitDisabled = val
  },

  setSortOrder: (state, val) => {
    state.sortOrder = val
  },

  setSortField: (state, val) => {
    state.sortField = val
  },

  setHeroOpen: (state, val) => {
    state.heroOpen = val
  },

  toggleFilter: (state) => {
    state.showFilter = !state.showFilter
  },

  setFolders: (state, folders) => {
    state.folders = folders
  },

  addFolder: (state, folder) => {
    state.folders = [...state.folders, folder]
  }
}
