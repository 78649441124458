import * as http from '../../../vue_shared/http'

interface BaseOptions {
  search_text?: string // eslint-disable-line camelcase
  page_number?: number // eslint-disable-line camelcase
}

type OptionType =
  | 'tags'
  | 'message_templates'
  | 'requesters'
  | 'staff'
  | 'departments'

interface Option {
  id: number
  name: string
}

interface FetchOptions extends BaseOptions {
  type: OptionType
}

export function fetchRequestDataByType(
  options: FetchOptions
): Promise<Option[]> {
  const { type, ...params } = options
  if (type !== 'message_templates' && type !== 'departments') {
    return http.get<Option[]>(`/client/requests/${type}/`, params)
  }
  if (type === 'message_templates') {
    return http.get<Option[]>(`/client/accounts/${type}/`, params)
  }
  if (type === 'departments') {
    return http.get<Option[]>(`/client/departments/`, params)
  }
}
