<template>
  <div :class="['nr-app', `page--${$route.name}`]">
    <app-header
      v-if="loaded"
      :account="account"
      :user="currentUser"
      :canManageInternalAdminTools="canManageInternalAdminTools"
      :canReadAdminOnly="canReadAdminOnly"
      :canReadStaffOnly="canReadStaffOnly"
      :canManageAll="canManageAll"
      :canPreviewUser="canPreviewUser"
      :permissionsPreviewUser="previewUser"
      :requestPrettyId="requestPrettyId()"
      @on-sign-out="handleSignOut"
    />
    <router-view v-if="loaded" />
    <Footer
      v-if="showFooter && loaded"
      :account="$store.state.account"
      :useStaffHelpLink="canReadStaffOnly"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import AppHeader from '../vue_shared/components/app_header.vue'
import Footer from '../vue_shared/components/Footer.vue'
import signOut from '../utils/sign_out'

export default {
  components: {
    'app-header': AppHeader,
    Footer
  },
  data() {
    return {
      loaded: false
    }
  },
  mounted() {
    Promise.all([this.fetchAbility(), this.fetchAccount()]).then(() => {
      this.$nextTick(() => {
        this.loaded = true
        this.$cookies.set('subdomain', this.account.subdomain)
      })
    })
  },
  computed: {
    ...mapState(['account', 'currentUser', 'previewUser']),
    ...mapState('request_show', ['request']),
    ...mapState('rapid_review', ['prettyId']),
    canManageInternalAdminTools() {
      return this.$can('manage', 'internal_admin_tools')
    },
    canReadStaffOnly() {
      return this.$can('read', 'staff_only_items')
    },
    canReadAdminOnly() {
      return this.$can('read', 'admin_only_items')
    },
    canManageAll() {
      return this.$can('manage', 'all')
    },
    canPreviewUser() {
      return this.$can('preview', 'User')
    },
    showFooter() {
      return !!(this.$route.meta && this.$route.meta.showFooter)
    }
  },
  methods: {
    ...mapActions(['fetchAbility', 'fetchAccount']),
    handleSignOut() {
      signOut(this.account)
    },
    requestPrettyId() {
      switch (this.$route.name) {
        case 'request-show':
          return this.request.pretty_id
        case 'rapid-review':
          return this.prettyId
        default:
          return null
      }
    }
  }
}
</script>

<style>
.nr-app {
  min-height: 100%;
}
</style>
