/* eslint-disable camelcase */
import { appToasted } from '../../../utils/vue-toasted/app_toasted.js'
import API from '../../../vue_shared/api' // eslint-disable-line import/no-useless-path-segments
import columns from '../components/columns'
import helpers from '../../../utils/helpers'
import * as http from '../../../vue_shared/http'
import { uniqBy } from 'lodash'

export default {
  deleteAllDocuments: ({ commit }) => {
    commit('setIsLoading', true)
    API.deleteDocuments()
      .then(() => {
        commit('setDocuments', [])
        commit('setAllDocumentsCount', 0)
        commit('setSearchResultsCount', 0)
        commit('setIsLoading', false)
      })
      .catch((error) => {
        commit('setIsLoading', false)
        if (error.response.status === 403) {
          appToasted().error('You do not have permission to delete documents.')
        } else {
          appToasted().error('An error occured. Please try again.')
        }
      })
  },

  fetchDocuments: ({ commit, state }) => {
    const payload = {
      search_term: state.searchTerm || undefined,
      upload_start_date: state.uploadDateRange[0],
      upload_end_date: state.uploadDateRange[1],
      document_start_date: state.documentDateRange[0],
      document_end_date: state.documentDateRange[1],
      sort_field: state.sortField,
      sort_order: state.sortOrder,
      page_size: state.perPage,
      page_number: state.currentPageNumber,
      folder_filter: state.folderFilter || undefined
    }
    commit('setIsLoading', true)
    API.fetchDocumentList(payload)
      .then((response) => {
        const documents = response.data.documents
        const totalCount = response.data.total_count
        if (documents.length) {
          commit('setDocuments', documents)
          commit('setColumns', columns)
          commit('setSearchResultsCount', totalCount)
          if (state.allDocumentsCount === 0) {
            commit('setAllDocumentsCount', totalCount)
          }
        } else {
          commit('setDocuments', [])
          commit('setSearchResultsCount', 0)
        }
        commit('setIsLoading', false)
      })
      .catch(() => {
        commit('setIsLoading', false)
        appToasted().error('An error occured. Please try again.')
      })
  },

  resetFilters: ({ dispatch, commit }) => {
    commit('setAllDocumentsCount', 0)
    commit('setCurrentPageNumber', 1)
    commit('setSearchTerm', '')
    commit('setUploadDateRange', { position: 'start', date: undefined })
    commit('setUploadDateRange', { position: 'end', date: undefined })
    commit('setDocumentDateRange', { position: 'start', date: undefined })
    commit('setDocumentDateRange', { position: 'end', date: undefined })
    commit('setSortOrder', 'desc')
    commit('setSortField', 'count')
    commit('setFolderFilter', '')
    dispatch('fetchDocuments')
  },

  setupAllDocsPageData: ({ dispatch, commit }, ability) => {
    Promise.all([API.fetchAbilityDocuments(), API.account()]).then(
      ([abilities, account]) => {
        // Set current user, user abilities and account
        const userAbilitiesResponse = abilities.data
        const accountResponse = account.data

        commit('setAbility', userAbilitiesResponse.rules, { root: true })

        if (userAbilitiesResponse.current_user) {
          commit('setCurrentUser', userAbilitiesResponse.current_user, {
            root: true
          })
        }

        commit('setAccount', accountResponse, { root: true })

        const heroOpen = !ability.can('read', 'staff_only_items')
        commit('setHeroOpen', heroOpen)
        helpers.setupDocColumns(columns, accountResponse, ability)
        dispatch('fetchDocuments')
      }
    )
  },

  setCurrentPageNumber: ({ commit }, val) => {
    commit('setCurrentPageNumber', val)
  },

  setSearchTerm: ({ commit }, val) => {
    commit('setSearchTerm', val)
  },

  setUploadDateRange: ({ commit }, { position, date }) => {
    if (['start', 'end'].includes(position)) {
      commit('setUploadDateRange', { position, date })
    }
  },

  setDocumentDateRange: ({ commit }, { position, date }) => {
    if (['start', 'end'].includes(position)) {
      commit('setDocumentDateRange', { position, date })
    }
  },

  setDocSubmitDisabled: ({ commit }, val) => {
    commit('setDocSubmitDisabled', val)
  },

  setSortOrder: ({ commit }, val) => {
    commit('setSortOrder', val)
  },

  setSortField: ({ commit }, val) => {
    commit('setSortField', val)
  },

  setSearchResultsCount: ({ commit }, val) => {
    commit('setSearchResultsCount', val)
  },

  setAllDocumentsCount: ({ commit }, val) => {
    commit('setAllDocumentsCount', val)
  },

  setFolderFilter: ({ commit }, val) => {
    commit('setFolderFilter', val)
  },

  addUploadedDocToState: ({ commit }, val) => {
    commit('addUploadedDocToState', val)
  },

  resetUploadedDocs: ({ commit }) => {
    commit('resetUploadedDocs')
  },

  saveDocs: ({ dispatch }, val) => {
    API.saveDocs(val)
      .then((response) => {
        if (response.data.message === 'no documents created') {
          appToasted().error(
            'An error occured creating document(s). Please try again.'
          )
          return
        }
        appToasted().success('Successfully uploaded document(s)')
        dispatch('resetUploadedDocs')
        dispatch('fetchDocuments')
      })
      .catch((error) => {
        if (error.response.status === 403) {
          appToasted().error('You do not have permission to upload documents.')
        } else {
          appToasted().error('An error occured. Please try again.')
        }
      })
  },
  toggleFilter: ({ commit }) => {
    commit('toggleFilter')
  },

  setHeroOpen: ({ commit }, val) => {
    commit('setHeroOpen', val)
  },

  resetFolders: ({ commit }) => {
    commit('setFolders', [])
  },
  bulkDocumentActions: async (_, payload) => {
    return API.bulkDocumentAction(payload)
  },
  fetchFolders: async ({ state, commit }, search_text = '') => {
    const newFolderFilterState = { ...state.folderFilterState }
    if (
      state.folderFilterState.search_text === search_text &&
      state.folderFilterState.end
    ) {
      return
    }
    if (state.folderFilterState.search_text === search_text) {
      Object.assign(newFolderFilterState, {
        search_text,
        page: state.folderFilterState.page + 1,
        fetching: true
      })
    }
    if (state.folderFilterState.search_text !== search_text) {
      Object.assign(newFolderFilterState, {
        search_text,
        page: 1,
        end: false,
        fetching: true
      })
    }
    const include_empty =
      state.folderFilterState.include_empty === undefined
        ? true
        : state.folderFilterState.include_empty
    const folderParams = {
      page_number: state.folderFilterState.page,
      search_text,
      include_empty
    }
    http
      .get('client/folders', folderParams)
      .then(({ folders }) => {
        if (state.folderFilterState.search_text !== search_text) {
          commit('setFolders', folders)
        } else {
          commit('setFolders', uniqBy([...state.folders, ...folders], 'id'))
        }
        commit(
          'setFolderFilterState',
          Object.assign(newFolderFilterState, {
            end: folders.length < 25,
            fetching: false
          })
        )
      })
      .catch((_error) => {
        appToasted().error('An error occured. Please try again.')
        commit('setFolderFilterState', { fetching: false })
      })
  },

  createFolder: async ({ commit, state }, name) => {
    try {
      const folder = await http.post('/client/folders', { folder_name: name })
      commit('setFolders', uniqBy([...state.folders, folder], 'id'))
      return folder
    } catch (error) {
      appToasted().error('An error occured. Please try again.')
    }
  }
}
