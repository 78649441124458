import rapidReviewState from './state'
import rapidReviewGetters from './getters'
import rapidReviewActions from './actions'
import rapidReviewMutations from './mutations'
import api from '../../../vue_shared/api'

const rapidReviewModule = {
  namespaced: true,
  state: Object.assign({}, rapidReviewState), // data
  getters: rapidReviewGetters, // computed properties
  actions: rapidReviewActions, // methods to retrieve data and call mutations
  mutations: rapidReviewMutations, // setting and updating state
  api // axios endpoints
}

export { rapidReviewModule }
