import axios from 'axios'

export default {
  activeDoc: '',
  activeDocId: '',
  activeFolder: ['', ''],
  activeModal: '',
  activeReviewState: 'Unprocessed',
  activeCheckAll: false,
  autoSaveState: '',
  checkedDocIds: [],
  currentUser: {},
  currentMarkupUpdatedAt: '',
  documents: {},
  docViewerPath: '',
  draftRedactionData: [],
  folders: [],
  folderOptions: [],
  getDocumentReviewListCancelToken: axios.CancelToken.source(),
  infiniteId: +new Date(),
  infinitePage: 1,
  jobStarted: false,
  marks: [],
  messageTemplateOptions: [],
  pageData: [],
  prettyId: '',
  processingDocIds: [],
  redactionComment: false,
  redactionLayerCount: 0,
  redactionLayerId: '',
  regexes: [],
  reviewStates: [
    { name: 'Unprocessed', value: 'Unprocessed' },
    { name: 'Non-responsive', value: 'Non-responsive' },
    { name: 'Needs redaction', value: 'Needs redaction' },
    { name: 'Redacted', value: 'Redacted' },
    { name: 'Ready for release', value: 'Ready' },
    { name: 'Originals', value: 'Processed' }
  ],
  reviewStateCounts: {
    'Needs redaction': 0,
    Processed: 0,
    Ready: 0,
    Redacted: 0,
    Unprocessed: 0,
    'Non-responsive': 0
  },
  rules: [],
  searchTerm: '',
  searchProcesses: {},
  testStrings: [{ name: 'this is a test' }, { name: 'second' }],
  viewingSessionId: '',
  totalDocCount: '',
  htmlTemplates: {
    draftRedactionComment: null,
    draftRedactionContextMenu: null,
    copyOverlay: null,
    downloadOverlay: null,
    esignOverlay: null,
    hyperlinkMenu: null,
    imageStampOverlay: null,
    overwriteOverlay: null,
    pageRedactionOverlay: null,
    printOverlay: null,
    print: null,
    redactionReason: null,
    unsavedChangesOverlay: null,
    draftRedactionViewer: null,
    markDelete: null
  },
  i18n: '',
  redactionReasons: {}
}
