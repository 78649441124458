/* eslint-disable no-param-reassign */
import { uniq } from 'lodash'

import rapidReviewInitialState from './state'

export default {
  addDocuments: (state, documents) => {
    state.documents = { ...state.documents, ...documents }
  },

  addProcessingDocIds: (state, ids) => {
    state.processingDocIds = uniq([...state.processingDocIds, ...ids])
  },

  setCheckedDocIds: (state, ids) => {
    state.checkedDocIds = uniq(ids)
  },

  emptyDocuments: (state) => {
    state.documents = {}
  },

  incrementInfiniteId: (state) => {
    state.infiniteId += 1
  },

  incrementInfinitePage: (state) => {
    state.infinitePage += 1
  },

  jobStarted: (state) => {
    state.jobStarted = true
  },

  setAutoSaveState: (state, val) => {
    state.autoSaveState = val
  },

  reinitializeState: (state) => {
    Object.assign(state, rapidReviewInitialState)
  },

  removeDocument: (state, id) => {
    delete state.documents[id]
  },

  removeProcessingDocIds: (state, ids) => {
    state.processingDocIds = state.processingDocIds.filter(
      (id) => !ids.includes(id)
    )
  },

  resetDocuments: (state) => {
    state.documents = {}
  },

  resetPageData: (state) => {
    state.pageData = []
  },

  resetInfinitePage: (state) => {
    state.infinitePage = 1
  },

  setActiveDoc: (state, val) => {
    state.activeDoc = val
  },

  setActiveDocId: (state, id) => {
    state.activeDocId = id
  },

  setAbility: (state, ability) => {
    state.rules = ability
  },

  setActiveFolder: (state, val) => {
    state.activeFolder = val
  },

  setActiveModal: (state, val) => {
    state.activeModal = val
  },

  setActiveReviewState: (state, val) => {
    state.activeReviewState = val
  },

  setActiveCheckAll: (state, val) => {
    state.activeCheckAll = val
  },

  setCurrentUser: (state, obj) => {
    state.currentUser = { ...obj }
  },

  setDocument: (state, document) => {
    state.documents[document.id] = document
  },

  setDocuments: (state, documents) => {
    state.documents = documents
  },

  setDraftRedactionData: (state, draftRedaction) => {
    state.draftRedactionData = [...state.draftRedactionData, draftRedaction]
  },

  setExistingMarks: (state, marks) => {
    state.marks = marks
  },

  setFolders: (state, folders) => {
    state.folders = [...new Set(folders)]
  },

  setChildFolders: (state, { folderId, folders }) => {
    for (const folder of state.folders) {
      if (folder.id === folderId) {
        folder.folders = folders
      }
    }
  },

  setFolderOptions: (state, folderOptions) => {
    state.folderOptions = folderOptions
  },

  setMessageTemplateOptions: (state, templates) => {
    state.messageTemplateOptions = templates
  },

  setPageData: (state, { pageNumber, pageData }) => {
    state.pageData[pageNumber] = pageData
  },

  setPrettyId: (state, val) => {
    state.prettyId = val
  },

  setRegexes: (state, regexes) => {
    state.regexes = regexes
  },

  setReviewStateCounts: (state, counts) => {
    state.reviewStateCounts = counts
  },

  setRedactionsLayerCount: (state, val) => {
    state.redactionLayerCount = val
  },

  setRedactionsLayerId: (state, val) => {
    state.redactionLayerId = val
  },

  setRedactionComment: (state, val) => {
    state.redactionComment = val
  },

  setSearchTerm: (state, val) => {
    state.searchTerm = val
  },

  setSearchProcessIds: (state, obj) => {
    state.searchProcesses = { ...state.searchProcesses, ...obj }
  },

  uncheckDoc: (state, val) => {
    state.checkedDocIds = state.checkedDocIds.filter((id) => id !== val)
  },

  updateRedactionLayer: (state, layer) => {
    const doc = state.activeDoc
    const idx = doc.redaction_layers.findIndex(
      (l) => l.layer_id === layer.layer_id
    )
    if (idx > -1) {
      doc.redaction_layers[idx] = layer
    } else {
      doc.redaction_layers.push(layer)
    }

    Object.assign(state.documents[layer.document_id], doc)
  },

  updateMarkupLayerUpdatedAtInState: (state, val) => {
    state.currentMarkupUpdatedAt = val
  },

  updateDocument: (state, { id, attrs }) => {
    state.documents[id] = Object.assign(state.documents[id], attrs)
  },

  setViewingSessionId: (state, val) => {
    state.viewingSessionId = val
  },

  setTotalDocCount: (state, val) => {
    state.totalDocCount = val
  },

  setRedactionReasons: (state, reasons = {}) => {
    state.redactionReasons = reasons
  }
}
