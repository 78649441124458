export default {
  methods: {
    togglePageScroll: function togglePageScroll(state: boolean = false) {
      if (typeof state === 'boolean') {
        document
          .querySelector('html')
          .classList.toggle('disable-page-scrolling', state)
      }
      togglePageScrollByWindowSize()
    },
    isSmallScreen: isSmallScreen,
    isSmallWidth: isSmallWidth,
    isSmallHeight: isSmallHeight
  }
}

export function togglePageScrollByWindowSize(forceState?: boolean) {
  const state = forceState ?? isSmallScreen()
  document
    .querySelector('html')
    .classList.toggle('disable-page-scrolling', !state)
}

export function isSmallScreen(): boolean {
  return isSmallWidth() || isSmallHeight()
}

export function isSmallWidth(): boolean {
  return window.innerWidth < 768
}

export function isSmallHeight(): boolean {
  return window.innerHeight < 768
}
