import documentsGetters from './getters'
import documentsActions from './actions'
import documentsMutations from './mutations'
import api from '../../../vue_shared/api'

type d = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9
type MM = `0${d}` | `1${0 | 1 | 2}`
type DD = `${0}${d}` | `${1 | 2}${0 | d}` | `3${0 | 1}`
type YYYY = `19${0 | d}${0 | d}` | `20${0 | d}${0 | d}`
type MMDDYYYY = `${MM}/${DD}/${YYYY}`

export interface State {
  allDocumentsCount: number
  currentPageNumber: number
  documents: []
  folders: string[]
  folderFilter: string
  columns: []
  documentsFolders: []
  docSubmitDisabled: boolean
  documentDateRange: [undefined | MMDDYYYY, undefined | MMDDYYYY]
  perPage: number
  pageNum: number
  rules: []
  searchResultsCount: number
  searchTerm: string
  sortField: string
  sortOrder: string
  isLoading: boolean
  showFilter: boolean
  uploadDateRange: [undefined | MMDDYYYY, undefined | MMDDYYYY]
  uploadedDocs: []
  heroOpen: boolean
  folderFilterState: {
    search_text: string // eslint-disable-line camelcase
    fetching: boolean
    end: boolean
    page: number
    include_empty: boolean // eslint-disable-line camelcase
  }
}

const documentsModule: { state: State; [key: string]: any } = {
  namespaced: true,
  state: {
    allDocumentsCount: 0,
    currentPageNumber: 1,
    documents: [],
    folders: [],
    folderFilter: '',
    columns: [],
    documentsFolders: [],
    docSubmitDisabled: true,
    documentDateRange: [undefined, undefined],
    perPage: 50,
    pageNum: 1,
    rules: [],
    searchResultsCount: 0,
    searchTerm: '',
    sortField: 'count',
    sortOrder: 'desc',
    isLoading: true,
    showFilter: true,
    uploadDateRange: [undefined, undefined],
    uploadedDocs: [],
    heroOpen: true,
    folderFilterState: {
      search_text: '',
      fetching: false,
      page: 0,
      end: false,
      include_empty: true
    }
  },
  getters: documentsGetters, // computed properties
  actions: documentsActions, // methods to retrieve data and call mutations
  mutations: documentsMutations, // setting and updating state
  api // axios endpoints
}

export { documentsModule }
