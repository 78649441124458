export default [
  {
    field: 'title',
    label: 'Document',
    pathName: 'document_path',
    sortable: true,
    hidden: false,
    maxChar: 60
  },
  {
    field: 'pretty_id',
    label: 'Request',
    pathName: 'request_path',
    sortable: true,
    hidden: false
  },
  {
    field: 'created_at',
    label: 'Upload Date',
    sortable: true,
    hidden: false
  },
  {
    field: 'count',
    label: 'Downloads',
    sortable: true,
    hidden: false
  },
  {
    field: 'state',
    label: 'Visibility',
    sortable: true,
    visible: false // hide this by default until a staff user is present
  },
  {
    field: 'folder_name',
    label: 'Folder',
    sortable: true,
    maxChar: 60,
    hidden: false
  },
  {
    field: 'redacted_at',
    label: 'Redacted Date',
    sortable: true,
    visible: false // hide this by default until a staff user and account is present
  },
  {
    field: 'doc_date',
    label: 'Document Date',
    sortable: true,
    hidden: false
  },
  {
    field: 'description',
    label: 'Document Description',
    sortable: false,
    maxChar: 120,
    hidden: false
  }
]
