<template>
  <footer
    :class="`footer is-hidden-print ${
      isSmallScreen() ? 'smallscreen-static-position' : 'bigscreen-left-padding'
    }`"
  >
    <div class="container level">
      <div class="level-left">
        <div class="level-item">
          <a href="/faqs" class="has-text-weight-bold">
            {{ faqHeaderText }}
          </a>
        </div>
        <div class="level-item">
          <a
            :href="helpLinkHref"
            class="has-text-weight-bold"
            target="_blank"
            rel="noopener noreferrer"
          >
            Help
          </a>
        </div>
        <div class="level-item">
          <a
            href="https://www.nextrequest.com/privacypolicy"
            class="has-text-weight-bold"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy
          </a>
        </div>
        <div class="level-item">
          <a
            href="https://www.nextrequest.com/termsofservice"
            class="has-text-weight-bold"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms
          </a>
        </div>
        <div v-if="accountLink" class="level-item">
          <!-- account.link_url is sanitized on the backend when it is created -->
          <a
            :href="account.link_url"
            class="has-text-weight-bold"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ accountLinkText }}
          </a>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <a
            href="https://www.nextrequest.com"
            aria-label="NextRequest an Optimere brand"
          >
            <NrLogo id="nr-logo-svg" />
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import truncate from 'lodash/truncate'
import NrLogo from '../../../images/nr-logo.svg'
import togglePageScroll from '../../mixins/toggle-page-scroll.ts'

export default {
  components: {
    NrLogo
  },
  mixins: [togglePageScroll],
  props: {
    account: {
      type: Object
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    useStaffHelpLink: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mobileFooter: false
    }
  },
  computed: {
    faqHeaderText() {
      if (this.account && this.account.faq_header) {
        return this.account.faq_header
      } else {
        return 'FAQS'
      }
    },
    accountLink() {
      if (this.account) {
        return this.account.link_text
      } else {
        return null
      }
    },
    accountLinkText() {
      return truncate(this.account.link_text, { length: 30 })
    },
    helpLinkHref() {
      return this.useStaffHelpLink
        ? 'https://www.nextrequest.civicplus.help/hc/en-us'
        : 'https://www.nextrequest.civicplus.help/hc/en-us/categories/17720084172567-Requesters'
    }
  }
}
</script>

<style lang="scss">
.mobile-footer {
  &-list {
    list-style: none;
    margin-bottom: 0;
    padding: 0;
  }
  &-list-item {
    list-style: none;
    padding: 12px 14px 14px 14px;
  }
  &-upbtn {
    position: absolute;
    left: 20px;
    bottom: 28px;
  }
}

.smallscreen-static-position {
  position: static !important;
}

.bigscreen-left-padding {
  padding-left: 96px;
}
</style>
