// Use dynamic imports to lazy load pages
const RapidReviewPage = () =>
  import(
    /* webpackChunkName: "RapidReviewPage" */ '../pages/rapid_review/components/RapidReviewPage.vue'
  )
const DocumentsPage = () =>
  import(
    /* webpackChunkName: "DocumentsPage" */ '../pages/documents/components/DocumentsPage.vue'
  )
const RequestsPage = () =>
  import(
    /* webpackChunkName: "RequestsPage" */ '../pages/requests/components/RequestsPage.vue'
  )
const RequestShowPage = () =>
  import(
    /* webpackChunkName: "RequestShowPage" */ '../pages/request_show/components/RequestShowPage.vue'
  )
const DocumentDashboard = () =>
  import(
    /* webpackChunkName: "DocumentDashboard" */ '../pages/admin/components/DocumentDashboard.vue'
  )

const CreateRequestPage = () =>
  import(
    /* webpackChunkName: "CreateRequestPage" */ '../pages/create_request/components/create_request.vue'
  )

export default [
  {
    path: '/app/rapidreview/:prettyId',
    name: 'rapid-review',
    component: RapidReviewPage,
    props: true
  },
  {
    path: '/documents',
    name: 'all-documents',
    component: DocumentsPage,
    meta: {
      title: 'Documents'
    }
  },
  {
    path: '/requests/new',
    name: 'new-request',
    component: CreateRequestPage,
    meta: {
      title: 'Make request',
      showFooter: true
    }
  },
  {
    path: '/requests',
    name: 'all-requests',
    component: RequestsPage,
    meta: {
      title: 'Requests'
    }
  },
  {
    path: '/requests/:prettyId',
    name: 'request-show',
    component: RequestShowPage,
    props: true,
    meta: {
      title: 'Request'
    }
  },
  {
    path: '/admin/document_dashboard',
    name: 'document-dashboard',
    component: DocumentDashboard,
    props: true,
    meta: {
      title: 'Document dashboard'
    }
  }
]
