import { merge, uniqBy, uniq } from 'lodash'

const FILTER_ARRAY_TYPES = [
  'close_request_type_ids',
  'department_ids',
  'request_helper_ids',
  'request_poc_ids',
  'requester_ids',
  'tag_ids'
]

export default {
  addUploadedReqToState: (state, val) => {
    state.uploadedReq.push(val)
  },

  setIsLoading: (state, val) => {
    state.isLoading = val
  },

  resetUploadedReq: (state) => {
    state.uploadedReq = []
  },

  setCurrentPageNumber: (state, val) => {
    state.currentPageNumber = val
  },

  setAllRequestsCount: (state, val) => {
    state.allRequestsCount = val
  },

  setRequests: (state, val) => {
    state.requests = val
  },

  setSearchResultsCount: (state, val) => {
    state.searchResultsCount = val
  },

  setReqSubmitDisabled: (state, val) => {
    state.reqSubmitDisabled = val
  },

  setColumns: (state, val = []) => {
    state.columns = val
  },

  toggleFilter: (state) => {
    state.showFilter = !state.showFilter
  },

  setTableHeaderTitle: (state, val) => {
    state.tableHeaderTitle = val
  },

  setTableHeaderTitleTooltip: (state, val) => {
    state.tableHeaderTitleTooltip = val
  },

  setSavedViews: (state, val = []) => {
    state.savedViews = val
  },

  setSelectedSavedView: (state, val = null) => {
    state.selectedSavedView = val
  },

  resetFilters: (state) => {
    state.filters = {
      close_request_type_ids: [],
      closed: false,
      department_ids: [],
      department_visible: false,
      due_date_end_date: '',
      due_date_start_date: '',
      due_soon: false,
      open: false,
      overdue: false,
      pending: false,
      published: false,
      request_date_end_date: '',
      request_date_start_date: '',
      request_helper_ids: [],
      request_poc_ids: [],
      requester_ids: [],
      restricted: false,
      search_term: '',
      staff_visible: false,
      tag_ids: [],
      sort_field: '',
      sort_order: 'desc',
      page_number: 1
    }
  },

  setFilters: (state, val) => {
    state.filters = { ...state.filters, ...val }

    for (const key in state.filters) {
      if (FILTER_ARRAY_TYPES.includes(key)) {
        state.filters[key] = uniq(state.filters[key])
      }
    }
  },

  setFilterFetchState: (state, value) => {
    state.filterFetchState = merge({}, state.filterFetchState, value)
  },

  setFilterOptions: (state, filters) => {
    Object.keys(filters).forEach((key) => {
      if (filters[key].length) {
        state.filterOptions[key] = uniqBy(
          [...state.filterOptions[key], ...filters[key]],
          'id'
        )
      } else {
        state.filterOptions[key] = []
      }
    })
  },

  resetHeaderTitle: (state) => {
    state.tableHeaderTitle = ''
    state.tableHeaderTitleTooltip = ''
  },

  setIsFetching: (state, val) => {
    state = Object.assign(
      {},
      state,
      {
        isFetching: { ...state.isFetching }
      },
      { isFetching: { ...state.isFetching, ...val } }
    )
  },

  setFilterData: (state, val) => {
    state.filterAutocompleteData = merge(
      {},
      state.filterAutocompleteData || {},
      val
    )
  },

  setResetSortIcons: (state, val) => {
    state.resetSortIcons = val
  },

  setHeroOpen: (state, val) => {
    state.heroOpen = val
  },

  setReportPendingStatus: (state, val) => {
    state.reportPending = val
  },

  setSearchIntegrations: (state, val) => {
    state.searchIntegrations = val
  }
}
