import { createMongoAbility } from '@casl/ability'
// Passing in empty rules because they will be set when retrieved from the backend.
export const ability = createMongoAbility([])
export const abilityPlugin = (store) => {
  ability.update(store.state.rules)
  return store.subscribe((mutation) => {
    if (mutation.type === 'setAbility') {
      const rules = mutation.payload
      ability.update(rules)
    }
  })
}
export default ability
