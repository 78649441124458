import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes.js'
import { togglePageScrollByWindowSize } from '../mixins/toggle-page-scroll'

export const router = createRouter({
  history: createWebHistory(),
  routes
})

const APP_TITLE = 'NextRequest - Modern FOIA & Public Records Request Software'
router.beforeEach(function (to, _, next) {
  const title = to.meta && to.meta.title
  let documentTitle = APP_TITLE

  togglePageScrollByWindowSize(true)

  switch (true) {
    case /\/requests\/new/i.test(to.fullPath):
      documentTitle = title ? `${title} - ${APP_TITLE}` : APP_TITLE
      break
    case /\/requests\//i.test(to.fullPath):
      documentTitle = title
        ? `${title} ${to.params.prettyId} - ${APP_TITLE}`
        : APP_TITLE
      break
    case /\/documents/i.test(to.fullPath):
    case /\/request/i.test(to.fullPath):
      documentTitle = title ? `${title} - ${APP_TITLE}` : APP_TITLE
      togglePageScrollByWindowSize()
      break
    default:
      documentTitle = title ? `${title} - ${APP_TITLE}` : APP_TITLE
  }

  document.title = documentTitle
  resetPageFocus()
  next()
})

function resetPageFocus() {
  document.body.setAttribute('tabindex', '-1')
  document.body.focus()
  document.body.removeAttribute('tabindex')
}
