import { appToasted } from '../../../utils/vue-toasted/app_toasted.js'
import {
  getDocumentPii,
  getMetrics,
  getDocumentList,
  updateDocReviewStatus
} from './document_dashboard_model'
import { ActionContext } from 'vuex'
import { State } from './state'
import { stringify as queryString } from '../../../vue_shared/query_params'
import { isEmpty } from 'lodash'
import NrSentry from '../../../utils/nr_sentry'

type VisibilityFilter = 'staff' | 'requester' | 'public'
type ReviewStates = 'needs_review' | 'reviewed'
type SeverityStates = 'high' | 'medium' | 'low' | 'na'

interface DocumentListParams {
  pageNumber: number
  state: VisibilityFilter
  reviewStatus: ReviewStates
  severity: SeverityStates
}

interface UpdatedReviewStatusParams {
  id: number
  reviewStatus: ReviewStates
}

interface DocumentPiiParams {
  state: VisibilityFilter
  reviewStatus: ReviewStates
  severity: SeverityStates
}
export interface Action {
  fetchDashboardMetrics(context: ActionContext<State, {}>): Promise<void>
  fetchDocumentPii(
    context: ActionContext<State, {}>,
    payload: DocumentPiiParams
  ): Promise<void>
  fetchDocumentList(
    context: ActionContext<State, {}>,
    payload: DocumentListParams
  ): Promise<void>
  setDocReviewStatus(
    context: ActionContext<State, {}>,
    params: UpdatedReviewStatusParams
  ): Promise<void>
}

export const actions: Action = {
  async fetchDashboardMetrics({ commit }) {
    try {
      const data = await getMetrics()
      commit('setDocumentMetrics', data)
    } catch (error) {
      appToasted().error(
        'An error has occured while retrieving your document visibility metrics.'
      )
      NrSentry.captureError(error)
    }
  },

  async fetchDocumentPii({ commit }, params) {
    const queryParams = !isEmpty(params)
      ? `?${queryString({
          state: params.state,
          review_status: params.reviewStatus,
          severity: params.severity
        })}`
      : ''
    try {
      const data = await getDocumentPii(queryParams)
      commit('setDocumentPii', data)
    } catch (error) {
      appToasted().error(
        'An error has occured while retrieving your document visibility metrics.'
      )
      NrSentry.captureError(error)
    }
  },

  async fetchDocumentList({ commit }, params) {
    const queryObj = {
      page_number: params.pageNumber,
      state: params.state,
      review_status: params.reviewStatus,
      severity: params.severity
    }
    const queryParams = `?${queryString(queryObj)}`
    try {
      const data = await getDocumentList(queryParams)
      commit('setDocumentList', data)
    } catch (error) {
      appToasted().error(
        'An error has occured while retrieving your document list.'
      )
      NrSentry.captureError(error)
    }
  },

  async setDocReviewStatus({ commit }, params) {
    const queryObj = {
      id: params.id,
      review_status: params.reviewStatus
    }
    try {
      const data = await updateDocReviewStatus(queryObj)
      commit('setDocument', data)
      appToasted().success('Successfully updated document review status.')
    } catch (error) {
      appToasted().error(
        'An error has occured while updating document review status.'
      )
      NrSentry.captureError(error)
    }
  }
}
