export interface NrSelectOption {
  label: string
  value: number
}

export const QUERY_FILTER_IDS_TO_OPTION_KEY_MAP = {
  close_request_type_ids: 'closure_responses',
  department_ids: 'departments',
  request_helper_ids: 'staff',
  request_poc_ids: 'pocs',
  requester_ids: 'requesters',
  tag_ids: 'tags'
}

/* eslint-disable camelcase */
export interface FilterState {
  close_request_type_ids?: number[] | NrSelectOption[]
  closed?: boolean
  department_ids?: number[] | NrSelectOption[]
  department_published?: boolean
  department_visible?: boolean
  due_date_end_date?: string
  due_date_start_date?: string
  due_soon?: boolean
  embargoed?: boolean
  open?: boolean
  overdue?: boolean
  page_number?: number
  pending?: boolean
  published?: boolean
  request_date_end_date?: string
  request_date_start_date?: string
  request_helper_ids?: number[] | NrSelectOption[]
  request_poc_ids?: number[] | NrSelectOption[]
  requester_ids?: number[] | NrSelectOption[]
  restricted?: boolean
  search_term?: string
  sort_field?: string
  sort_order?: string
  staff_visible?: boolean
  tag_ids?: number[] | NrSelectOption[]
}

export const filterState: FilterState = {
  close_request_type_ids: [],
  closed: false,
  department_ids: [],
  department_published: false,
  department_visible: false,
  due_date_end_date: '',
  due_date_start_date: '',
  due_soon: false,
  embargoed: false,
  open: false,
  overdue: false,
  pending: false,
  published: false,
  request_date_end_date: '',
  request_date_start_date: '',
  request_helper_ids: [],
  request_poc_ids: [],
  requester_ids: [],
  restricted: false,
  search_term: '',
  staff_visible: false,
  tag_ids: [],
  sort_field: '',
  sort_order: 'desc',
  page_number: 1
}
