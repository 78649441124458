import * as http from '../../../vue_shared/http'

interface ClosureResponse {
  id: number
  name: string
  description: string
}

export function getClosureResponse(id: number): Promise<ClosureResponse> {
  return http.get<ClosureResponse>(`/client/requests/closure_responses/${id}`)
}

interface Tag {
  id: number
  tag_name: string // eslint-disable-line camelcase
}

export function getTag(id: number): Promise<Tag> {
  return http.get<Tag>(`/client/requests/tags/${id}`)
}
