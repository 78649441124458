// eslint-disable-next-line vue/custom-event-name-casing
import requestShowGetters from '../pages/request_show/store/getters'
import requestShowActions from '../pages/request_show/store/actions'
import requestShowMutations from '../pages/request_show/store/mutations'
import requestShowState from '../pages/request_show/store/state'
import api from '../vue_shared/api.js'

const requestShowModule = {
  namespaced: true,
  state: Object.assign({}, requestShowState), // data
  getters: requestShowGetters, // computed properties
  actions: requestShowActions, // methods to retrieve data and call mutations
  mutations: requestShowMutations, // setting and updating state
  api // axios endpoints
}

export { requestShowModule }
