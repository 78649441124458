export default {
  allDocumentsCount: (state) => state.allDocumentsCount,
  currentPageNumber: (state) => state.currentPageNumber,
  columns: (state) => state.columns,
  documents: (state) => state.documents,
  documentDateRange: (state) => state.documentDateRange,
  docSubmitDisabled: (state) => state.docSubmitDisabled,
  docUploadFolderOption: (state) => state.docUploadFolderOption,
  perPage: (state) => state.perPage,
  searchTerm: (state) => state.searchTerm,
  sortOrder: (state) => state.sortOrder,
  sortField: (state) => state.sortField,
  showFilter: (state) => state.showFilter,
  isLoading: (state) => state.isLoading,
  searchResultsCount: (state) => state.searchResultsCount,
  totalCount: (state) => state.totalCount,
  uploadDateRange: (state) => state.uploadDateRange,
  uploadedDocs: (state) => state.uploadedDocs,
  folderFilter: (state) => state.folderFilter,
  heroOpen: (state) => state.heroOpen
}
