export default {
  request: (state) => state.request,
  documents: (state) => state.documents,
  requestStaff: (state) => state.requestStaff,
  requestTags: (state) => state.requestTags,
  requestTasks: (state) => state.requestTasks,
  currentRequestTasksCount: (state) => state.currentRequestTasksCount,
  totalRequestTasksCount: (state) => state.totalRequestTasksCount,
  totalRequestTimelineCount: (state) => state.totalRequestTimelineCount,
  requestTimeline: (state) => state.requestTimeline,
  requestInvoices: (state) => state.requestInvoices,
  documentsFolders: (state) => state.documentsFolders,
  searchDocumentResultsCount: (state) => state.searchDocumentResultsCount,
  currentDocumentsCount: (state) => state.currentDocumentsCount,
  totalDocumentsCount: (state) => state.totalDocumentsCount,
  currentDocumentFilters: (state) => state.currentDocumentFilters,
  selectedChangeDocVisibility: (state) => state.selectedChangeDocVisibility,
  previewRequestPage: (state) => state.previewRequestPage,
  messageToRequester: (state) => state.messageToRequester,
  activeModal: (state) => state.activeModal,
  displayExternalMsgBox: (state) => state.displayExternalMsgBox,
  displayInternalMsgBox: (state) => state.displayInternalMsgBox,
  displayCreateTaskMsgBox: (state) => state.displayCreateTaskMsgBox,
  currentAction: (state) => state.currentAction,
  editingTask: (state) => state.editingTask,
  folderLimit: (state) => state.folderLimit,
  totalDocumentsFoldersCount: (state) => state.totalDocumentsFoldersCount,
  requestDocumentRisk: (state) => state.requestDocumentRisk
}
