import { Action, actions } from './actions'
import { Mutations, mutations } from './mutations'
import { state, State } from './state'

interface AdminModule {
  namespaced: boolean
  state: State
  mutations: Mutations
  actions: Action
}

export const adminModule: AdminModule = {
  namespaced: true,
  state,
  mutations,
  actions
}
