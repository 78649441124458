import { isEmpty, isEqual, mapValues, pick, pickBy } from 'lodash'
import { FilterState, filterState } from '../../../store/filters-state'

type FilterKey = keyof FilterState

export function parseFilterParams(filters: FilterState): FilterState {
  const knownFilters = pick(filters, deltaFilterKeys(filters))
  const parsedFilters = mapValues<FilterState>(
    knownFilters,
    (value: any, key: FilterKey) => {
      switch (typeof filterState[key]) {
        case 'object':
          return itemsToNumberType(value)
        default:
          return value
      }
    }
  )

  const changedFilters = pickBy(parsedFilters, (value, key) => {
    return !isEqual(value, filterState[key])
  })

  return changedFilters
}

function deltaFilterKeys(state: FilterState): FilterKey[] {
  const keys = []

  // Cycle through the state keys
  for (const key in state) {
    // Check the key against the initial filter state
    switch (typeof filterState[key]) {
      case 'number':
        keys.push(key)
        break
      case 'string':
        if (state[key]) keys.push(key)
        break
      case 'boolean':
        if (state[key]) keys.push(key)
        break
      case 'object':
        if (!isEmpty(state[key])) keys.push(key)
        break
    }
  }

  return keys
}

function itemsToNumberType(items: any): number[] {
  if (Array.isArray(items)) {
    return items.map(itemToNumberType)
  } else {
    return [Number(items)]
  }
}

function itemToNumberType(item: any): number {
  if (typeof item !== 'object') {
    return Number(item)
  } else {
    // NOTE: Assume it's coming from NrSelect
    return Number(item.value)
  }
}
